import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './styles/theme';
import { GlobalStyles } from './styles/global';
import { ThemeContext } from './context/themeContext';
import AuthenticatedApp from './layouts/protected';
import Alert from './components/Alert';
import UnauthenticatedApp from './layouts/guest';
import './App.scss';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import ApolloProviderWithAuth from './layouts/client';
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from './constants';

function Home() {
  const { isAuthenticated, isLoading, error } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
   return <div>Oops... {error.message}</div>;
  }

  return isAuthenticated ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp />
  );
  // return <AuthenticatedApp />
}

function App() {
  const context = useContext(ThemeContext);
  const { theme } = context;

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <>
        <Alert />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyles />
            <Auth0Provider
              domain={AUTH0_DOMAIN}
              clientId={AUTH0_CLIENT_ID}
              redirectUri={window.location.origin}
            >
            <ApolloProviderWithAuth>

              {/* <AuthProvider> */}

                <div>
                  <Home />
                </div>
              {/* </AuthProvider> */}
              </ApolloProviderWithAuth>
            </Auth0Provider>
          </PersistGate>
        </Provider>
      </>
    </ThemeProvider>
  );
}

export default App;
