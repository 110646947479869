import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import styled from 'styled-components';
const Container = styled.div`
  margin-top: 5rem;
`;

const ProfileImg = styled.img`
  height: 5rem;
`;
const ProfileName = styled.h1`
  font-size: 1rem;
  font-weight: 300;
  color: ${({ theme }) => theme.textColor};
`;

const ProfileImage = () => {
  const { user } = useAuth0();

  return <img src={user?.picture} className="mr-2" alt="face" />;
};

export default ProfileImage;
