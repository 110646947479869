import React from 'react';
import Login from '../user-pages/Login';
import { Form } from 'react-bootstrap';

import Logo from './../logo.svg';
import Logo2 from './../logo2.svg';
import { useAuth0 } from '@auth0/auth0-react';

function UnauthenticatedApp({
}: {
}) {
  const { loginWithRedirect } = useAuth0();



  return (
    <>
      {/* <div className="container-login100">
      </div> */}

    <div className="container-login100">
			<div className="wrap-login100">
				<div className="login100-pic">
          <img src={Logo2} className="card-img-absolute logo-2" alt="circle" />
				</div>

				<div className="login100-form validate-form">
					<span className="login100-form-title">
          {/* <img src={Logo} className="card-img-absolute" alt="circle" width="200"/> */}
					</span>
          <button className="google-btn" onClick={() => loginWithRedirect()}>Log In</button>;

				</div>
			</div>
		</div>

    </>
  );
}

export default UnauthenticatedApp;
