import React, { useEffect, useState } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import SubCategories from '../containers/subCategories';
import Categories from '../containers/categories';
import Dashboard from '../containers/Dashboard';
import Nav from '../components/Main/Nav';
import VariantProducts from '../containers/variantProducts';
import { createUploadLink } from 'apollo-upload-client';
import { alertService } from '../alert.service';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import ThemeApp from '../themeapp';
import { API } from '../constants';
import { useAuth0 } from '@auth0/auth0-react';

// function Footer() {
//   return <p>This is an awesome app!</p>;
// }

// function Header() {
//   const { profile } = useAuthState();
//   return <p>Hello {profile?.name}</p>;
// }

function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const httpLink = createUploadLink({
  uri: API,
  headers: {
    'Apollo-Require-Preflight': true,
  },
});

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = getCookie('authToken');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       usegapiadmin: true,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      alertService.error(message);
      if (message.includes('not authorized')) {
        window.location.href = '/';
      }
    });
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}`,
      JSON.stringify(networkError, null, 2),
    );
    if (
      ((networkError as unknown) as { statusCode: number }).statusCode === 401
    ) {
      // Cookies.remove('authToken');
      window.location.href = '/';
    }
    // TODO handle all network related errors here for REST API
  }
});

// const client = new ApolloClient({
//   link: ApolloLink.from([errorLink, authLink, httpLink]),
//   cache: new InMemoryCache(),
// });

const MainContainer = styled.div`
  width: auto;
  margin-left: 16rem;
  position: relative;
  padding: 0 4rem;
`;

function AuthenticatedApp() {

  return (
    <>
      <Router>
        <ThemeApp />
      </Router>
    </>
  );
}

export default AuthenticatedApp;
