import React, { useEffect, useState } from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import SubCategories from '../containers/subCategories';
import Categories from '../containers/categories';
import Dashboard from '../containers/Dashboard';
import Nav from '../components/Main/Nav';
import VariantProducts from '../containers/variantProducts';
import { createUploadLink } from 'apollo-upload-client';
import { alertService } from '../alert.service';
import { onError } from '@apollo/client/link/error';
import Cookies from 'js-cookie';
import ThemeApp from '../themeapp';
import { API } from '../constants';
import { useAuth0 } from '@auth0/auth0-react';


// function getCookie(name: string) {
//   const matches = document.cookie.match(
//     new RegExp(
//       '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)',
//     ),
//   );
//   return matches ? decodeURIComponent(matches[1]) : undefined;
// }

// TODO: can be removoed
// const httpLink = createUploadLink({
//   uri: API,
//   headers: {
//     'Apollo-Require-Preflight': true
//   }
// });

// TODO: can be removed
// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = getCookie('authToken');
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       usegapiadmin: true,
//       authorization: token ? `Bearer ${token}` : '',
//     },
//   };
// });

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      alertService.error(message);
      if (message.includes('not authorized')) {
        window.location.href = '/';
      }
    });
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}`,
      JSON.stringify(networkError, null, 2),
    );
    if (
      ((networkError as unknown) as { statusCode: number }).statusCode === 401
    ) {
      // Cookies.remove('authToken');
      window.location.href = '/';
    }
    // TODO handle all network related errors here for REST API
  }
});

// TODO: can be removed
// export const client = new ApolloClient({
//   link: ApolloLink.from([errorLink, authLink, httpLink]),
//   cache: new InMemoryCache(),
// });

// ---

const cache = new InMemoryCache();

function createApolloClient(accessToken) {
  const authLink = setContext((request, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(accessToken && {
          Authorization: `Bearer ${accessToken}`
        })
      }
    };
  });

  const httpLink = createUploadLink({
    uri: API,
    headers: {
      'Apollo-Require-Preflight': true
    }
  });

  return new ApolloClient({ cache, link: ApolloLink.from([errorLink, authLink, httpLink]) });
}

export function ApolloProviderWithAuth({ children }) {
  const [accessToken, setAccessToken] = useState();
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  useEffect(() => {
    (async () => {
      let tokenFromAuth0;

      try {
        tokenFromAuth0 = await getAccessTokenSilently({
          // FIXME: not working
          // audience: window.location.origin
        });
      } catch (err) {
        if (err.error === 'consent_required') {
          tokenFromAuth0 = await getAccessTokenWithPopup({
            // FIXME: not working
            // audience: window.location.origin,
            // prompt: 'consent',
          });
        }
      }

      if (tokenFromAuth0) {
        setAccessToken(tokenFromAuth0);
      }
    })();
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  const client = createApolloClient(accessToken);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default ApolloProviderWithAuth;
