import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import styled from 'styled-components';


const ProfileName = () => {
  const { user } = useAuth0();

  return user?.name || '...';
};

export default ProfileName;
